<template>
  <div class="top-filter-wrap">
    <div class="top-filter">
      <ul class="flex" v-if="filters.length">
        <li @click="filterEvent('')" :class="{ active: checkTopFilter('') }">
          <span class="wadapatra-filter-all">सबै सेवाहरु</span>
        </li>
        <li
          v-for="(ele, index) in filters"
          :key="index"
          @click="filterEvent(ele.id)"
          :class="{ active: checkTopFilter(ele.id) }"
        >
          <span :class="generateClassname(ele.label)">{{ ele.label }}</span>
        </li>
      </ul>
    </div>
    <div class="flex flex-end flex-filter-service">
      <div class="flex mb-16">
        <!-- <div class="filter-service" @click="toggleFilter">
            <i>Service</i>
          </div>  -->
        <div class="top-filter-mob multiselect__full-width">
          <Multiselect
            v-model="value"
            :options="filters"
            :placeholder="'सबै सेवाहरु'"
            :canClear="false"
          />
        </div>
      </div>
      <div class="download-pdf-container">
        <button
          @click="$store.dispatch('windowPrint')"
          class="btn download-pdf"
        >
          PDF डाउनलोड गर्नुहोस्
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
export default {
  props: {
    filters: { type: Array, required: true },
  },
  data() {
    return {
      selectedFilter: "",
      value: null,
      showFilter: false,
      default: {
        placeholder: "multiselect-placeholder",
      },
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    checkTopFilter(data) {
      if (data === this.selectedFilter) return true;

      return false;
    },
    filterEvent(data) {
      this.selectedFilter = data;
      this.$emit("changeInTopFilter", data);
    },
    generateClassname(label) {
      var filterPerson = "wadapatra-filter";
      switch (label) {
        case "व्यक्तिगत घटना दर्ता सम्बन्धी ":
          filterPerson = "wadapatra-filter-person";
          break;
        case "स्वास्थ्यसँग सम्बन्धित सेवा":
          filterPerson = "wadapatra-filter-health";
          break;
        case "ज्येष्ठ नागरिक एवं एकल महिला सम्बन्धी ":
          filterPerson = "wadapatra-filter-older";
          break;
        case "उद्याेग/व्यवसायसँग एवं संघ संस्थासँग सम्बन्धित":
          filterPerson = "wadapatra-filter-industry";
          break;
        case "घरजग्गासँग सम्बन्धित ":
          filterPerson = "wadapatra-filter-home";
          break;
        default:
          filterPerson = "wadapatra-filter";
      }
      return filterPerson;
    },
    toggleFilter: function () {
      console.log(this.showFilter);
      this.showFilter = !this.showFilter;
    },
  },
  watch: {
    showFilter: function () {
      document.querySelector("#filter-sidebar").style.display = this.showFilter
        ? "block"
        : "";
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.top-filter {
  @media all and (max-width: 768px) {
    display: none;
  }
  margin: 0 auto;
  padding: 8px 0 16px;
  &-wrap {
    padding: 0 20px;
    .download-pdf-container {
      display: none;
      @media all and (max-width: 768px) {
        display: block;
      }
      @media all and (max-width: 480px) {
        width: 100%;
      }
    }
    .download-pdf {
      @media all and (max-width: 480px) {
        width: 100%;
      }
    }
  }
  ul {
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1240px;
    li {
      border-bottom: 3px solid transparent;
      padding-bottom: 8px;
      span {
        cursor: pointer;
        display: flex;
        &:hover {
          color: $primary;
          &:before {
            filter: none;
          }
        }
        &:before {
          background-image: url(../../assets/ic_sprite.svg);
          background-repeat: no-repeat;
          content: "";
          display: block;
          height: 20px;
          filter: brightness(0);
          margin-right: 8px;
          width: 20px;
        }
      }
      &.active {
        border-color: $primary;
        span {
          color: $primary;
          &:before {
            filter: none;
          }
        }
      }
    }

    .wadapatra-filter {
      &-all:before {
        background-position: -96px 0;
      }
      &-person:before {
        background-position: -96px -40px;
      }
      &-health:before {
        background-position: -96px -81px;
      }
      &-older:before {
        background-position: -96px -123px;
      }
      &-industry:before {
        background-position: -96px -163px;
      }
      &-home:before {
        background-position: -98px -204px;
      }
    }
  }
  &-mob {
    display: none;
    @media all and (max-width: 768px) {
      display: flex;
    }
  }
}
.filter-service {
  i {
    background: url(../../assets/ic_filter.svg) no-repeat 0 0;
    display: block;
    height: 40px;
    margin-right: 20px;
    text-indent: -9999px;
    width: 40px;
  }
}
</style>
